<template>
    <div>
        <div style="margin-bottom: 20px;">
            <el-input style="width:50%" v-model="setprice" placeholder="输入价格"></el-input>
            <el-button @click="setmoney">设置诊费金额</el-button>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                setprice: '',
                yfid:''
            }
        },
        mounted() {
            this.axios.get('/gu/get_gu_keyval_by_group?group=pat_servorder').then(res => {
                if (res.data.code == 0) {
                    this.yfid = res.data.result[1].val;
                }
            });
        },
        methods: {
            setmoney() {
                //设置金额
                this.axios.post('/yf/pat_doctor_setserv', this.qs.stringify({ yf_id: this.yfid, price: this.setprice, }))
                    .then(res => {
                        console.log(res);
                        if (res.data.res == 'OK') {
                            this.$message({
                                message: '设置成功',
                                type: 'success'
                            });
                        }
                    })
            },
        },
    }
</script>